.section-sixth {
  margin-bottom: 100px;
  .header {
    padding-top: 100px;
    background: rgb(251, 250, 251);
    background: -moz-linear-gradient(top, rgba(251, 250, 251, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(251, 250, 251, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(251, 250, 251, 1) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbfafb', endColorstr='#ffffff', GradientType=0);
    h5 {
      color: $main-brown;
      text-align: center;
      font-weight: 900;
      font-size: 2rem;
      line-height: 125%;
      margin-bottom: 3rem;
      padding: 0 120px;
    }
  }
  .advantages-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .advantages-number {
      width: 42px;
      height: 42px;
      border-radius: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffc61b;
      box-shadow: 0px 10px 30px 0px rgba(255,198,27,0.35);
      margin-right: 25px;
      span {
        width: 42px;
        text-align: center;
      }
    }
    .advantages-content {
      h6 {
        color: $main-brown;
        text-align: left;
        font-weight: 400;
        font-size: 1.75rem;
        line-height: 115%;
        margin-bottom: .75rem;
      }
      p {
        font-size: 1.15rem;
        color: $main-brown;
        text-align: left;
        margin-bottom: 1rem;
      }
    }
  }
}