#projects {
  margin-bottom: 4rem;
   h5 {
     font-family: "Roboto Condensed", sans-serif;
     color: #212f42;
     font-weight: 900;
     font-size: 2.15rem;
     line-height: 125%;
     text-align: center;
     margin-bottom: 4rem;
   }
  .project-card {
    height: 340px;
    border-radius: 4px;
    .project-info {
      border-radius: 4px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(70,44,198,.75) 100%);
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(70,44,198,.75) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(70,44,198,.75) 100%);
      h6 {
        font-family: "Roboto Condensed", sans-serif;
        color: #ffffff;
        margin-left: 3rem;
        margin-bottom: 3rem;
        font-size: 1.5rem;
      }
    }
    &.project-dorogi {
      background-image: url('../../img/projects/prodorogi.jpg');
      background-size: cover;
      background-position: center center;
    }
    &.project-plasmonic {
      background-image: url('../../img/projects/plasmonic.jpg');
      background-size: cover;
      background-position: center center;
    }
    &.project-torgi {
      background-image: url('../../img/projects/torgi.jpg');
      background-size: cover;
      background-position: center center;
    }
    &.project-jkh {
      background-image: url('../../img/projects/jkh.jpg');
      background-size: cover;
      background-position: center center;
    }
  }
}