.btn-primary {
  background: $main-yellow;
  border: none;
  color: #26272f;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem 1.75rem;
  outline: none;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all .2s;
  text-align: right;
  line-height: 110%;
  span {
    margin-right: 8px;
  }
  &:hover {
    background: #ffc61b;
    box-shadow: none;
    transition: all .2s;
    color: #26272f;
  }
  &:active {
    background: #ffc61b !important;
    box-shadow: none !important;
    outline: none !important;
    color: $main-brown !important;
  }
  &:focus {
    background: #ffc61b;
    box-shadow: none !important;
  }
}

.form-section {
  padding: 5rem 2rem 5rem 4rem;
  h6 {
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.85rem;
    text-align: left;
  }
  h5 {
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.85rem;
    font-weight: 700;
    text-transform: none;
    text-align: left;
  }
  form {
    margin-top: 3rem;
    label {
      font-size: .7rem;
      color: white;
    }
    .form-control {
      border: none;
      border-radius: 4px;
      padding: .75rem 1.5rem;
      &::-webkit-input-placeholder {
        font-family: "Roboto", sans-serif;
        color: #6f7d8c;
        font-size: .85rem;
      }
      &::-moz-placeholder {
        font-family: "Roboto", sans-serif;
        color: #6f7d8c;
        font-size: .85rem;
      }
    }
    .button-container {
      margin-top: 1.5rem;
      .form-btn {
        border-radius: 4px;
        color: #26272f;
        font-size: 1rem;
        span {
          margin-right: 8px;
        }
      }
      p {
        font-size: .75rem;
        color: white;
        padding-left: 8px;
        font-weight: 300;
        span {
          margin-right: 8px;
        }
      }
    }

  }
}

.modal-dialog {
  .modal-content {
    .modal-header {
      border: none;
      padding: 5px 15px;
      button {
        cursor: pointer;
      }
    }
    .modal-body {
      padding: 5px 25px 15px;
      h5 {
        font-weight: 700;
        font-size: 1.5rem;
        text-transform: uppercase;
        text-align: center;
        color: #283141;
        margin-bottom: 1rem;
      }
      p {
        color: #283141;
        font-size: 1.1rem;
        font-weight: 400;
      }
      button {
        padding: 1rem 1.75rem;
        font-size: 1.15rem;
        margin: 1rem 0;
        color: #fff;
      }
      .form-control {
        &:focus {
          box-shadow: 0px 0px 20px 0px rgba(255, 198, 27, 0.4);
        }
      }
    }
  }
}

main {
  overflow-x: hidden;
}

.nowrap {
  white-space: nowrap;
}

#questions-modal {
  .modal-content {
    background: url('../../img/modalbg.png');
    background-size: cover;
    background-position: center center;
    border: none;
    .modal-body {
      padding: 0;
      button.close {
        margin: 0;
        padding: 1rem 1rem;
        position: absolute;
        right: 0;
        opacity: 1;
        top: 0;
        span {
          font-size: 1.15rem;
          color: #28354d;
          opacity: 1;
          text-shadow: none;
        }
      }
    }
    .form-section {
      padding: 5rem 2rem 5rem 4rem;
      h6 {
        color: white;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 1.85rem;
        text-align: left;
      }
      h5 {
        color: white;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 1.85rem;
        font-weight: 700;
        text-transform: none;
        text-align: left;
      }
      form {
        margin-top: 3rem;
        label {
          font-size: .7rem;
          color: white;
        }
        .form-control {
          border: none;
          border-radius: 4px;
          padding: .75rem 1.5rem;
          &::-webkit-input-placeholder {
            font-family: "Roboto", sans-serif;
            color: #6f7d8c;
            font-size: .85rem;
          }
          &::-moz-placeholder {
            font-family: "Roboto", sans-serif;
            color: #6f7d8c;
            font-size: .85rem;
          }
        }
        .button-container {
          margin-top: 1.5rem;
          .form-btn {
            border-radius: 4px;
            color: #26272f;
            font-size: 1rem;
            span {
              margin-right: 8px;
            }
          }
          p {
            font-size: .75rem;
            color: white;
            padding-left: 8px;
            font-weight: 300;
            span {
              margin-right: 8px;
            }
          }
        }

      }
    }
  }
  .modal-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    height: 100%;
    .logo-container {
      padding: 4rem;
      img {
        max-width: 140px;
      }
    }
  }
}