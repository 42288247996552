@media (min-width: 992px) {
  .modal-lg {
    max-width: 920px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .heading-section .content .offer-section {
    margin-top: 2rem;
    margin-bottom: 100px;
  }
  .cooperation-form_container {
    .form-section {
      padding: 2rem;
    }
  }
  #cooperation .cooperation-img {
    padding: 0;
    text-align: center;
  }
  #questions-modal .modal-logo .logo-container {
    padding: 2rem;
  }
  #questions-modal .modal-content .form-section {
    padding: 2rem;
  }
  #contacts .contacts-row {
    flex-wrap: wrap;
  }
  #contacts .contacts-button {
    margin-top: 2rem;
  }
}

@media (max-width: 767px) {
  .heading-section .content {
    padding-top: 32px;
    padding-bottom: 0;
    position: relative;
  }
  .heading-section .header-section_top {
    flex-wrap: wrap;
    justify-content: center;
    .header-links {
      margin-top: 2rem;
    }
  }
  .heading-section .content .offer-section .offer-card {
    padding: 1rem;
  }
  .cooperation-form_container .form-section {
    padding: 1rem;
  }
  #contacts .contacts-row .contacts-cards .contacts-card h6 {
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
  }
  #contacts .contacts-row .contacts-cards {
    flex-wrap: wrap;
    height: 100%;
    .divider {
      width: 0;
    }
    .contacts-card {
      margin-bottom: 2rem;
    }
  }
  #contacts .contacts-button {
    margin-top: 2rem;
  }
  #questions-modal .modal-logo .logo-container {
    padding: 0;
    display: none;
  }
  #questions-modal .modal-logo {
    background: transparent;
  }
  #questions-modal .modal-content .modal-body button.close span {
    font-size: 1.15rem;
    color: #fff;
    opacity: 1;
    text-shadow: none;
  }
}

@media (max-width: 575px) {
  html {
    font-size: 14px;
  }
  .heading-section .header-section_top .header-links {
    margin-top: 3rem;
  }

  .heading-section .content .offer-section .offer-card .offer-info h6 {
    font-size: 1.5rem;
  }
  .heading-section .content .offer-section .offer-card .offer-info p {
    font-size: 1.25rem;
  }
  #tech .tech-card .card-info h6 {
    font-size: 1.75rem;
  }
  #tech .tech-card .card-info p {
    font-size: 1.05rem;
  }
  .form-section form label {
    font-size: .8rem;
    color: white;
  }
  .divider-second {
    display: none;
  }
  #contacts .contacts-row .contacts-cards {
    align-items: flex-start;
  }
}


@media (max-width: 385px) {
  html {
    font-size: 13px;
  }
  .divider-second {
    display: none;
  }
  .heading-section .header-section_top .header-links ul li {
    margin-right: 16px;
  }
}

@media (max-width: 350px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 325px) {

}
