$main-brown: #3d1914;
$orange: #fe6d04;
$main-yellow: #ffe200;
$shadow-color: #26272f;


@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

.btn-main {
  text-transform: uppercase;
  color: #fff;
  background-color: #242c3b;
  padding: .5rem 3rem;
  &:hover {
    color: #fff;
  }
}

.questionMark {
  color: #5d7395;
  background: #e6e9ee;
  border-radius: 25px;
  padding: 6px 9px;
  font-size: .9rem;
  margin-left: 12px;
}

.tooltip-inner {
  background-color: #fff;
  color: #20242d;
  font-size: .9rem;
  border: 2px solid #d8dce3;
  border-radius: 5px;
}

.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before, .tooltip.tooltip-top .tooltip-inner::before {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #d8dce3;
}