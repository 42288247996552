#tech {
  padding-top: 60px;
  background: url('../../img/bgsecond.png');
  background-position: 60% 60%;
  background-size: 132%;
  background-repeat: no-repeat;
  padding-bottom: 120px;
  .tech-card {
    border-radius: 4px;
    padding: 2rem;
    box-shadow: 0px 5px 15px 0px rgba(46,61,73,0.15);
    background-color: #fff;
    height: 100%;
    .card-info {
      padding-left: 50px;
      h6 {
        font-family: "Roboto", sans-serif;
        color: #293955;
        font-size: 1.5rem;
        font-weight: 700;
      }
      p {
        font-family: "Roboto Condensed", sans-serif;
        color: #6f7d8c;
        margin-bottom: 0;
        font-size: .95rem;
      }
    }
    &.tech-card_node {
      background-image: url('../../img/tech/node.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.tech-card_react {
      background-image: url('../../img/tech/react.png');
      background-size: 120px;
      background-position: -50px center;
      background-repeat: no-repeat;

    }
    &.tech-card_django {
      background-image: url('../../img/tech/django.png');
      background-size: 48px;
      background-position: 10px center;
      background-repeat: no-repeat;
    }
    &.tech-card_laravel {
      background-image: url('../../img/tech/laravel.png');
      background-size: 110px;
      background-position: -42px center;
      background-repeat: no-repeat;
    }
    &.tech-card_postgres {
      background-image: url('../../img/tech/postgres.png');
      background-size: 110px;
      background-position: -45px center;
      background-repeat: no-repeat;
    }
    &.tech-card_graph {
      background-image: url('../../img/tech/graph.png');
      background-size: 116px;
      background-position: -42px center;
      background-repeat: no-repeat;
    }
    &.tech-card_socket {
      background-image: url('../../img/tech/socket.png');
      background-size: 100px;
      background-position: -32px center;
      background-repeat: no-repeat;
    }
    &.tech-card_heroku {
      background-image: url('../../img/tech/heroku.png');
      background-size: 100px;
      background-position: -42px center;
      background-repeat: no-repeat;
    }
  }
}