.heading-section {
  background: url('../../img/bgheader.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  .header-section_top {
    padding: 1.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 56px;
      }
      .logo-info {
        margin-left: 18px;
        .logo-name {
          font-family: Qanelas, sans-serif;
          margin-bottom: 0;
          font-size: 32px;
          line-height: 32px;
        }
        p {
          font-family: Roboto, sans-serif;
          font-weight: 400;
          margin-bottom: 0;
          font-size: 12px;
          color: white;
        }
      }
    }
    .header-links {
      ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-right: 32px;
          &:last-of-type {
            margin-right: 0;
          }
          a {
            color: white;
            text-transform: uppercase;
            font-size: .8rem;
            font-weight: 500;
          }
        }
      }
    }
  }
  .content {
    padding-top: 64px;
    padding-bottom: 0;
    position: relative;
    .header-text_info {
      margin-top: 2rem;
      color: #ffdc00;
      font-size: 1.2rem;
      font-weight: 300;
      margin-bottom: 1rem;
    }
    h1 {
      color: white;
      font-weight: 900;
      font-size: 2.15rem;
      line-height: 125%;
      margin-bottom: 1.5rem;
    }
    p.title-description {
      font-size: 1.62rem;
      font-weight: 300;
      line-height: 32px;
      color: #ebd1fe;
      margin-bottom: 2rem;
    }
    .buttons-container {
      margin-top: 4rem;
    }
    .offer-section {
      margin-top: 2rem;
      margin-bottom: 200px;
      h5 {
        font-family: "Roboto Condensed", sans-serif;
        color: white;
        font-weight: 900;
        font-size: 2.15rem;
        line-height: 125%;
        margin-bottom: 4rem;
      }
      .offer-card {
        height: 100%;
        background-color: #fff;
        border-radius: 4px;
        padding: 1.5rem;
        box-shadow: 0px 5px 30px 0px rgba(46,61,73,0.2);
        .offer-img {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .offer-info {
          margin-top: 1.5rem;
          h6 {
            color: #293955;
            font-weight: 900;
            font-size: 1.15rem;
            margin-bottom: 1.5rem;
          }
          p {
            font-family: "Roboto Condensed", sans-serif;
            margin-bottom: .5rem;
            color: #6f7d8c;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .section-tech_title {
    font-family: "Roboto Condensed", sans-serif;
    color: #212f42;
    font-weight: 900;
    font-size: 2.15rem;
    line-height: 125%;
    text-align: center;

  }
}