#contacts {
  background: url('../../img/bgbottom.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  h5 {
    font-family: "Roboto Condensed", sans-serif;
    color: #212f42;
    font-weight: 900;
    font-size: 2.15rem;
    line-height: 125%;
    text-align: center;
    margin-bottom: 3rem;
  }
  .map-container {
    border-radius: 4px;
    margin-bottom: 120px;
    position: relative;
    box-shadow: 0px 5px 25px 0px rgba(46, 61, 73, 0.15);
    background-color: white;
    #map {
      width: 100%;
      height: 500px;
      position: relative;
      z-index: 5;
    }
    .map-bg {
      padding: 0 2rem;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 2;
      text-align: center;
    }
    .map-bg_small {
      padding: 0 5rem;
      position: absolute;
      top: 120px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      z-index: 1;
    }
  }
  .contacts-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-BOTTOM: 1px solid #a79df2;
    .contacts-cards {
      display: flex;
      align-items: center;
      .divider {
        width: 1px;
        height: 70%;
        background-color: white;
        margin: 0 2rem;
      }
      .contacts-card {
        h6 {
          font-size: 1.5rem;
          font-weight: 700;
          color: white;
        }
        p {
          font-family: "Roboto Condensed", sans-serif;
          color: white;
          margin-bottom: 0;
        }
      }
    }
  }
  .copyright {
    p {
      margin-bottom: 0;
      padding: 1rem 0;
      color: rgba(255,255,255, .5);
      font-family: "Roboto Condensed", sans-serif;
    }
  }
}