#cooperation {
  margin-bottom: 4rem;
  .cooperation-form_container {
    .form-section {
      h6, h5 {
        color: #212f42;
      }
      label {
        color: #26272f;
      }
      .form-control {
        box-shadow: 0px 0px 20px 0px rgba(46, 61, 73, 0.1);
        &:focus {
          box-shadow: 0px 0px 20px 0px rgba(46, 61, 73, 0.2);
        }
      }
      .button-container {
        margin-top: 2.5rem;
        p {
          margin-top: 1.5rem;
          color: #6f7d8c;
        }
      }
    }
  }
  .cooperation-img {
    padding: 2rem;
  }
}