.map {
  #map {
    height: 500px;
    width: 100%;
  }
  .map-content {
    position: relative;
    .map-descriptor {
      position: absolute;
      top: 50px;
      left: 50px;
      background-color: #fff;
      max-width: 320px;
      border-radius: 8px;
      .descriptor-body {
        padding: 2.25rem;
        padding-bottom: 0;
      }
      h6 {
        color: $main-brown;
        text-align: left;
        font-weight: 900;
        font-size: 2.15rem;
        line-height: 115%;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.15rem;
        color: $main-brown;
        text-align: left;
        margin-bottom: 1rem;
        line-height: 120%;
      }
      a.footer-tel {
        font-size: 1.4rem;
        font-weight: 700;
        font-family: "Open Sans", sans-serif;
        color: #3d1914;
      }
      .questions {
        color: #fe6d04;
        display: flex;
        align-items: center;
        margin-left: 0;
        margin-bottom: 1.75rem;
        p {
          margin-left: 7px;
          font-size: .75rem;
          font-weight: 700;
          font-family: "Open Sans", sans-serif;
          color: #fe6d04;
          text-decoration: underline;
          margin-bottom: 0;
        }
      }
      .descriptor-footer {
        border-top: 2px solid #ededed;
        .content {
          padding: 1.5rem 2.25rem 2.25rem;
          p {
            font-size: 1rem;
            color: $main-brown;
            text-align: left;
            margin-bottom: 0;
            line-height: 120%;
          }
          a {
            color: $orange;
            font-size: 1rem;
          }
        }
      }
    }
  }
}